<template>
  <div class="inside-page-content">
    <div class="paper all-center">
      <div class="top-row">
        <img :src="detailData.pictureUrl" class="top-img"/>
        <div class="top-info">
          <div class="top-name">
            <label class="type">{{detailData.institutionName}}</label>
          </div>
          <div class="condition">
            <label>所在地址：{{detailData.address}}</label>
            <label>投资行业：{{investmentIndustryDic[detailData.investmentIndustry]}}</label>
          </div>
        </div>
      </div>
      <div class="desc">
        <div class="desc-item">
          <label class="info-text">投资方式</label><label class="info-text">{{investmentModeDic[detailData.investmentMode]}}</label>
        </div>
        <div class="desc-item">
          <label class="info-text">联系人</label><label class="info-text">{{detailData.contactName}}</label>
        </div>
<!--        <div class="desc-item">-->
<!--          <label class="info-text">贷款期限</label><label class="info-text">{{detailData.contactName}}月</label>-->
<!--        </div>-->
        <div class="desc-item">
          <label class="info-text">联系电话</label><label class="info-text">{{detailData.contactPhone}}</label>
        </div>
        <div class="desc-item">
          <label class="info-text">电子邮箱</label><label class="info-text">{{detailData.contactEmail}}</label>
        </div>
      </div>
      <!--分割线-->
      <div class="divider"></div>

      <div class="detail-content">
        <div class="content-item">
          <div class="content-title">机构简介</div>
          <div class="content-text">{{detailData.institutionIntroduction}}</div>
        </div>
        <div class="content-item">
          <div class="content-title">投资偏好</div>
          <div class="content-text">{{detailData.investmentPreference}}</div>
        </div>
        <div class="content-item">
          <div class="content-title">已投资项目</div>
          <div class="content-text">{{detailData.investedProject}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopFilter from './components/topFilter.vue'
export default {
  name: 'capitalDetail',
  components: {
    TopFilter
  },
  data() {
    return {
      investmentIndustryDic: {1:'电子信息',2:'生物医药',3:'先进制造',4:'现代服务业',5:'现代农业',6:'新能源与节能技术',7:'资源与环境',8:'生物产业',9:'软件开发',10:'其他'},
      investmentModeDic: {1:'间接投资',2:'直接投资',3:'作价投资',4:'其他'},
      id:null,
      detailData:{}
    }
  },
  computed:{
  },
  created(options) {
    this.id = this.$route.query?.id
    this.getDetail()
  },
  mounted() {

  },
  methods: {
    getDetail() {
      this.$api.technologyFinance.investmentDetail(this.id).then((res) => {
        this.detailData = res.data.data
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

.top-row {
  height: 130px;
  padding: 0 80px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-around;
  .top-img{
    height:67px;
    width:117px;
  }
  .top-info{
    height: 100%;
    flex: 1;
    margin-left: 40px;
    display: flex;
    align-items: start;
    flex-direction: column;
    justify-content: space-around;
    .top-name{
      display: flex;
      align-items: center;
      flex-direction: row;
      .type{
        font-weight: 700;
      }
    }
    .condition{
      font-size: 12px;
      display: flex;
      flex-direction: column;
    }
  }
}
.desc {
  display: flex;
  flex-direction: row;
  padding: 20px 80px;
  justify-content: space-around;
  .desc-item{
    display: flex;
    flex-direction: column;
    width: 20%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    .info-text{
      padding-top: 10px;
      font-size: 14px;
      font-weight: 200;
    }
  }
}
.divider{
  width: 95%;
  margin: 20px auto;
  border-bottom: 1px dashed grey;
}
.detail-content{
  margin: 20px 80px;
  .content-item{
    margin: 40px 0;
    .content-title{
      border-left: 5px solid blue;
      padding-left: 10px;
      font-weight: 700;
    }
    .content-text{
      margin-top: 10px;
      font-size: 14px;
    }
  }

}

</style>
